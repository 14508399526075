import logo from './logo.svg';
import './App.css';
import Index from './components/Home Page/Index'
function App() {
  return (
   <div>
    <Index/>
   </div>
  );
}

export default App;
